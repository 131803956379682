
import cmsBlock from '~/mixins/cmsBlock'

export default {
  name: 'CmsMediclub3Block1',
  mixins: [cmsBlock],

  data () {
    return {
      fallbackContent: {
        title: '<strong>UNLOCK</strong>YOUR POTENTIAL',
        imgUrl: '/mc/v3/block1/ppl.png',
        subtitle: 'and <b>be well, feel good with 300 000 satisfied Members</b>',
        footerLoggedIn: 'Cześć jestem <strong>zalogowany</strong>.',
        footerLoggedOut: 'Cześć jestem <strong>wylogowany</strong>.',
      },
    }
  },
}
