
import cmsBlock from '~/mixins/cmsBlock'

export default {
  name: 'CmsMediclub3Block2',
  mixins: [cmsBlock],

  data () {
    return {
      fallbackContent: {
        title: 'Why its worth to join us',
        joinBtn: 'Join to MediClub',
        items: [
          {
            img: '/mc/v3/block2/1.png',
            title: 'Access',
            text: 'Get an access to all our applications and platforms by using one account. Easy and comfortable.',
          },
          {
            img: '/mc/v3/block2/2.png',
            title: 'Discounts',
            text: 'Take advantage of discounts and special offers available only for Members. ',
          }, {
            img: '/mc/v3/block2/3.png',
            title: 'Discover ',
            text: 'Discover unique  content. The best podcasts, articles and trainings that will improve your mood and quality of life. ',
          }, {
            img: '/mc/v3/block2/4.png',
            title: 'SelfCare',
            text: 'Get an access to the SelfCare program, where we will take care of your health and well-being.',
          }, {
            mobile: false,
            img: '/mc/v3/block2/5.png',
            title: 'Your Birthday',
            text: 'We always remember about your Birthday! On this sepcial day we send you special gift',
          },
        ],
      },
    }
  },
}
